@font-face {
    font-family: 'IIHF 2024 Text';
    src: url('../fonts/Iihf2024TextRegular.woff2') format('woff2'),
    url('../fonts/Iihf2024TextRegular.otf') format('otf'),
    url('../fonts/Iihf2024TextRegular.ttf') format('ttf');
    font-weight: normal;
}

@font-face {
    font-family: 'IIHF 2024 Text';
    src: url('../fonts/Iihf2024TextSemibold.woff2') format('woff2'),
    url('../fonts/Iihf2024TextSemibold.otf') format('otf'),
    url('../fonts/Iihf2024TextSemibold.ttf') format('ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'IIHF 2024 Display';
    src: url('../fonts/Iihf2024Display.woff2') format('woff2'),
    url('../fonts/Iihf2024Display.otf') format('otf'),
    url('../fonts/Iihf2024Display.ttf') format('ttf');
    font-weight: 400;
}